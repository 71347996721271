import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { map } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-conference-registration',
  templateUrl: './conference-registration.component.html',
  styleUrls: ['./conference-registration.component.scss']
})
export class ConferenceRegistrationComponent implements OnInit{
  @Input() conferenceId;
  @Input() email;
  
  public form: FormGroup;
  unsubcribe: any
  public loading = false;

  public fields = []

  // public fields: any[] = [
  //   {
  //     type: 'text',
  //     name: 'firstName',
  //     label: 'First Name',
  //     value: '',
  //     required: true,
  //   },
  //   // {
  //   //   type: 'text',
  //   //   name: 'lastName',
  //   //   label: 'Last Name',
  //   //   value: '',
  //   //   required: true,
  //   // },
  //   // {
  //   //   type: 'text',
  //   //   name: 'email',
  //   //   label: 'Email',
  //   //   value: '',
  //   //   required: true,
  //   // },

  //   // {
  //   //   type: 'file',
  //   //   name: 'picture',
  //   //   label: 'Picture',
  //   //   required: true,
  //   //   onUpload: this.onUpload.bind(this)
  //   // },
  //   // {
  //   //   type: 'dropdown',
  //   //   name: 'country',
  //   //   label: 'Country',
  //   //   value: 'in',
  //   //   required: true,
  //   //   options: [
  //   //     { key: 'in', label: 'India' },
  //   //     { key: 'us', label: 'USA' }
  //   //   ]
  //   // },
  //   // {
  //   //   type: 'radio',
  //   //   name: 'country',
  //   //   label: 'Country',
  //   //   value: 'in',
  //   //   required: true,
  //   //   options: [
  //   //     { key: 'm', label: 'Male' },
  //   //     { key: 'f', label: 'Female' }
  //   //   ]
  //   // },
  //   // {
  //   //   type: 'checkbox',
  //   //   name: 'hobby',
  //   //   label: 'Hobby',
  //   //   required: true,
  //   //   options: [
  //   //     { key: 'f', label: 'Fishing' },
  //   //     { key: 'c', label: 'Cooking' }
  //   //   ]
  //   // }
  // ];

  constructor(private service:AppService,private modalService: BsModalService) {
    

    this.form = new FormGroup({
      fields: new FormControl(JSON.stringify(this.fields))
    })
    
    this.unsubcribe = this.form.valueChanges.subscribe((update) => {
      console.log(update);
      this.fields = JSON.parse(update.fields);
    });
  }
  ngOnInit() {
    // console.log(this.conferenceId)
    // console.log(this.conferenceId)
    // console.log(this.conferenceId)
    // console.log(this.conferenceId)
    this.loading = true

    this.service.getConferencesSeminarsWithFields(this.conferenceId).pipe(map(res=>res['data']['form_inputs'])).subscribe(Response=>{
      var arr = []
      console.log(Response)

      Response.forEach(field => {
        // public fields: any[] = [
  //   {
  //     type: 'text',
  //     name: 'firstName',
  //     label: 'First Name',
  //     value: '',
  //     required: true,
  //   },
  //   // {
  //   //   type: 'text',
  //   //   name: 'lastName',
  //   //   label: 'Last Name',
  //   //   value: '',
  //   //   required: true,
  //   // },
  //   // {
  //   //   type: 'text',
  //   //   name: 'email',
  //   //   label: 'Email',
  //   //   value: '',
  //   //   required: true,
  //   // },

  //   // {
  //   //   type: 'file',
  //   //   name: 'picture',
  //   //   label: 'Picture',
  //   //   required: true,
  //   //   onUpload: this.onUpload.bind(this)
  //   // },
  //   // {
  //   //   type: 'dropdown',
  //   //   name: 'country',
  //   //   label: 'Country',
  //   //   value: 'in',
  //   //   required: true,
  //   //   options: [
  //   //     { key: 'in', label: 'India' },
  //   //     { key: 'us', label: 'USA' }
  //   //   ]
  //   // },
  //   // {
  //   //   type: 'radio',
  //   //   name: 'country',
  //   //   label: 'Country',
  //   //   value: 'in',
  //   //   required: true,
  //   //   options: [
  //   //     { key: 'm', label: 'Male' },
  //   //     { key: 'f', label: 'Female' }
  //   //   ]
  //   // },
  //   // {
  //   //   type: 'checkbox',
  //   //   name: 'hobby',
  //   //   label: 'Hobby',
  //   //   required: true,
  //   //   options: [
  //   //     { key: 'f', label: 'Fishing' },
  //   //     { key: 'c', label: 'Cooking' }
  //   //   ]
  //   // }
  // ];

        if(field.type == 'select' || field.type == 'checkbox' || field.type == 'radio'){
          var values = [];
          (field.value).split('**').forEach(function(val,index,array){
            values.push({ key: array[index], label: array[index] })
          })
          arr.push({
            type: field.type,
            name: field.program_name,
            label: field.virtual_name_en,
            value: field.value,
            required: Number(Boolean(field.is_optional)),
            options:values
          })
        }else if(field.type == 'file'){
    //            type: 'file',
    //   name: 'picture',
    //   label: 'Picture',
    //   required: true,
    //   onUpload: this.onUpload.bind(this)
    // },
          arr.push({
            type: field.type,
            name: field.program_name,
            label: field.virtual_name_en,
            value: field.value,
            required: Number(Boolean(field.is_optional)),
            onUpload:this.onUpload.bind(this)
          })
        }
        
        else{
          arr.push({
            type: field.type,
            name: field.program_name,
            label: field.virtual_name_en,
            value: field.value,
            required: Number(Boolean(field.is_optional)),
          })
        }
        
      });
      this.fields = arr 
      // console.clear()
      // console.log(this.fields)
      this.getFields()
      this.loading = false


    //   ...(field.type == 'select' && {options: [
    //     // var x = "ذكر**انثى";
    //     // var result = x.split('**');
    //     (field.value).split('**').forEach(function(val,index,array){
    //       array[index] ;
    //     })

    //     // field.value.forEach(element => {
    //     //   return
    //     // })
    // //             { key: 'in', label: 'India' },
    // // { key: 'us', label: 'USA' }
    //   ]})




//       conferences_seminars_id: "1"
// created_at: "2022-06-14T14:14:28.000000Z"
// current_virtual_name: "phone number"
// id: 4
// is_multiple: "0"
// is_optional: "0"
// program_name: "phone_number"
// type: "text"
// updated_at: "2022-06-14T14:14:28.000000Z"
// value: null
// virtual_name_ar: "الرقم"
// virtual_name_en: "phone number"


      // {
      //   type: 'text',
      //   name: 'firstName',
      //   label: 'First Name',
      //   value: '',
      //   required: true,
      // },

    })

  }
  onUpload(e) {
    console.log(e);

  }

  getFields() {
   
    return this.fields;
  }

  ngDistroy() {
    this.unsubcribe();
  }

}
