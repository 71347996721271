import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.scss']
})
export class MarketingComponent implements OnInit {
  public data;
  constructor(private spinner: NgxSpinnerService,private service:AppService) { }

  ngOnInit(): void {
    this.get('markting')
  }

  get(key){
    this.spinner.show()
    this.service.pageDataByKey(key).pipe(map(response=>response['data']['main_section'])).subscribe(response=>{
      console.log(response)
      this.data = response
      this.spinner.hide()
    })
  }
}
