import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  public submitted = false;
  public form:FormGroup;

  constructor(private router: Router,private formbuilder:FormBuilder,private service:AuthenticationService,private spinner:NgxSpinnerService) { 
  }

  ngOnInit(): void {
    this.form = this.formbuilder.group({
      name: ['', [Validators.required]],
      company: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone : ['', [Validators.required]],
      message : ['', [Validators.required]],
    }) 
  }

  get f() {return this.form.controls}
  submit(){
    this.submitted = true;
    if (this.form.invalid) { 
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });return 
    }
    console.log(this.form.value)
    alert(JSON.stringify({...this.form.value}))
    this.spinner.show()
    this.service.contactus(this.form.value).subscribe((response:any)=>{
      // console.log(response)
      // this.spinner.hide()
          // Swal.fire(
          //   `success`,
          //   `Welcome back, ${response.user.name}`,
          //   `success`
            
          // )
          this.router.navigate(["/home"])
    })
  }

}
