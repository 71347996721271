import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private router: Router,private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem(`${environment.currentUserKey}`)));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    if(this.currentUserSubject.value != null) { return this.currentUserSubject.value }        
  }
  
  login(form){
    const formData: FormData = new FormData();
    formData.append("email", form.email);
    formData.append("password", form.password);
    // new Response(formData).text().then(// console.log)
    return this.http.post(`${environment.apiUrl}/auth/client/login`, formData)
    .pipe( map((user:any) => {
      user = user.data
        if (user && user.access_token) {
            localStorage.setItem(`${environment.currentUserKey}`, JSON.stringify(user));
            this.currentUserSubject.next(user);
        } return user;
    }));
    }

    register(form){
      console.log(form)
      const formData: FormData = new FormData();
      formData.append("fname_ar", form.firstNameAr);
      formData.append("mname_ar", form.middleNameAr);
      formData.append("lname_ar", form.lastNameAr);
      formData.append("fname_en", form.firstName);
      formData.append("mname_en", form.middleName);
      formData.append("lname_en", form.lastName);
      formData.append("email", form.email);
      formData.append("password", form.password);
      formData.append("title", form.title);
      formData.append("nationality", form.nationality.id);
      formData.append("country_id", form.residence.id);
      formData.append("specilaization", form.specialization);
      formData.append("specilaization_organization_number", form.specializationNumber);
      formData.append("country_code1", form.mobile.dialCode);
      formData.append("phone_number1", form.mobile.number);
      if(form.mobile2Nd){
        formData.append("country_code2", form.mobile.dialCode);
        formData.append("phone_number2", form.mobile.number);
      }
      return this.http.post(`${environment.apiUrl}/auth/client/new_register`, formData)
      .pipe( map((user:any) => {
          // if (user && user.data.access_token) {
          //     localStorage.setItem(`${environment.currentUserKey}`, JSON.stringify(user));
          //     this.currentUserSubject.next(user);
          // } 
          return user;
      })
      );
    }
 

    logout() {
      localStorage.removeItem(`${environment.currentUserKey}`);
      this.currentUserSubject.next(null);
      this.router.navigate(['/login'])
  }
  countries(){
    return this.http.get(`${environment.apiUrl}/countries?with_paginate=no`)
  }
  profile(){
    return this.http.get(`${environment.apiUrl}/user/profile`)
  }
  info(){
    return this.http.get(`${environment.apiUrl}/auth/info`)
  }
  sendCodeToEmail(email,type_code = 'register'){
    return this.http.post(`${environment.apiUrl}/auth/client/send/code/email`,{email,type_code})
  }
  verifyCode(email,code){
    return this.http.post(`${environment.apiUrl}/auth/client/code/email/verify_code`,{email,code})
  }

  contactus(form){
    const formData: FormData = new FormData();
    formData.append("name", form.name);
    formData.append("company_name", form.company);
    formData.append("email", form.email);
    formData.append("phone_number", form.phone);
    formData.append("message", form.message);
    return this.http.post(`${environment.apiUrl}/contactus`, formData)
    }

    resetPassword(form){
      const formData: FormData = new FormData();
      formData.append("email", form.email);
      formData.append("code", form.code);
      formData.append("password", form.password);
      formData.append("c_password", form.c_password);
      return this.http.post(`${environment.apiUrl}/auth/client/resetPassword`, formData)
      }
    
}