import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActivitiesComponent } from './components/activities/activities.component';
import { ConfirmComponent } from './components/auth/contact-us/confirm/confirm.component';
import { ContactUsComponent } from './components/auth/contact-us/contact-us.component';
import { LoginComponent } from './components/auth/login/login.component';
import { ProfileComponent } from './components/auth/profile/profile.component';
import { RegisterComponent } from './components/auth/register/register.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
import { BlogComponent } from './components/blog/blog.component';
import { CareersComponent } from './components/careers/careers.component';
import { ConferenceComponent } from './components/conference/conference.component';
import { ConferencesComponent } from './components/conferences/conferences.component';
import { ExhibitionsComponent } from './components/exhibitions/exhibitions.component';
import { FreeCounselingComponent } from './components/free-counseling/free-counseling.component';
import { HomeComponent } from './components/home/home.component';
import { MarketingComponent } from './components/marketing/marketing.component';
import { MediaAndDocumentationComponent } from './components/media-and-documentation/media-and-documentation.component';
import { OurBusinessComponent } from './components/our-business/our-business.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ReturnsPolicyComponent } from './components/returns-policy/returns-policy.component';
import { SocialResponsibilityComponent } from './components/social-responsibility/social-responsibility.component';
import { TarainingComponent } from './components/taraining/taraining.component';
import { VirtualTourComponent } from './components/virtual-tour/virtual-tour.component';
import { WorkshopsComponent } from './components/workshops/workshops.component';
import { AuthGuard } from './guards/auth.guard';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'profile', component: ProfileComponent ,canActivate: [AuthGuard]},
  { path: 'confirm/:email', component: ConfirmComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  
  { path: 'our-business', component: OurBusinessComponent },
  { path: 'exhibitions', component: ExhibitionsComponent },
  { path: 'activities', component: ActivitiesComponent },
  { path: 'media-and-documentation', component: MediaAndDocumentationComponent },
  { path: 'marketing', component: MarketingComponent },
  { path: 'workshops', component: WorkshopsComponent },
  { path: 'taraining', component: TarainingComponent },
  { path: 'free-counseling', component: FreeCounselingComponent },
  { path: 'social-responsibility', component: SocialResponsibilityComponent },
  { path: 'conferences', component: ConferencesComponent },
  { path: 'conference/:id', component: ConferenceComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'virtual-tour', component: VirtualTourComponent },

  { path: 'careers', component: CareersComponent },
  { path: 'return-policy', component: ReturnsPolicyComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
