import { Component, OnInit, ViewEncapsulation } from '@angular/core';
declare var pannellum: any;
import AOS from 'aos';

@Component({
  selector: 'app-virtual-tour',
  templateUrl: './virtual-tour.component.html',
  styleUrls: ['./virtual-tour.component.scss','../conferences/conferences.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class VirtualTourComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    AOS.init();

    pannellum.viewer('panorama', {
      "type": "equirectangular",
      "panorama":  "https://pannellum.org/images/alma.jpg",
      "autoLoad": true,
      "compass": false,
      
    });
    
  }

}
