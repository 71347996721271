import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class ConfirmComponent implements OnInit {
  @ViewChild('template') templateRef: TemplateRef<any>;

  public submitted = false;
  public form:FormGroup;
  optlength = 4;
  leftTime = 30;
  optentered = []
  countdown
  modalRef?: BsModalRef;
 


  constructor(private modalService: BsModalService,private route:ActivatedRoute,private router: Router,private formbuilder:FormBuilder,private service:AuthenticationService,private spinner:NgxSpinnerService) { 
    if (this.service.currentUserValue) { this.router.navigate(['/']) }
  }
  ngOnInit(): void {
    // this.sendCode(this.route.snapshot.params['email'])
    this.form = this.formbuilder.group({
      code:['',Validators.required],
    })
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  
  sendCode(email = this.route.snapshot.params['email']){
    this.spinner.show()
    this.service.sendCodeToEmail(email).pipe(map(response=>response['data'])).subscribe(response=>{
      console.log(response)
      this.spinner.hide()
    })
  }

  get f() {return this.form.controls}
  submit(){
    this.submitted = true;
    if (this.form.invalid) { 
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });return 
    }
    // alert(JSON.stringify({...this.form.value}))
    this.spinner.show()
    this.service.verifyCode(this.route.snapshot.params['email'],this.form.value['code']).subscribe((response:any)=>{
      console.log(response)
      console.log(response)
      this.spinner.hide()
      if(response.data.status_check){
        this.modalRef = this.modalService.show(this.templateRef);
      }else{
        Swal.fire(
          `error`,
          `An error occurred .. Please try again`,
          `error`
        )
      }
    })
  }
}
