import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { CertificateComponent } from './certificate/certificate.component';
import AOS from 'aos';
import { map, take } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicFormBuilderComponent } from './dynamic-form-builder/dynamic-form-builder.component';
import { ConferenceRegistrationComponent } from './conference-registration/conference-registration.component';

@Component({
  selector: 'app-conference',
  templateUrl: './conference.component.html',
  styleUrls: ['./conference.component.scss','../conferences/conferences.component.scss']
})
export class ConferenceComponent implements OnInit {
  joinStatus
  public submitted = false;
  public form:FormGroup;
  conference
  recommended = []
  public isJoined : boolean = false;
  public loadingJoinButton : boolean = false;
  private bsModalRef?: BsModalRef;
  parentsSliderOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 4
      },
      740: {
        items: 6
      },
      940: {
        items: 7
      }
    },
    nav: false
  }
  constructor(private formbuilder:FormBuilder,private modalService: BsModalService,private spinner:NgxSpinnerService,private service:AppService,private route: ActivatedRoute) { 
    this.service.subject$.subscribe(val => {
      console.log(val);
      this.getConference(this.route.snapshot.paramMap.get('id'))
      this.checkConferenceJoinEmail2()

    });
  }

  ngOnInit(): void {
    AOS.init();
    this.getConference(this.route.snapshot.paramMap.get('id'))
    this.form = this.formbuilder.group({
      email: [null, Validators.compose([Validators.required, Validators.email])]
    })
  }
  pay(){
    this.spinner.show()
    this.service.conferencePay(this.form.value['email'],this.route.snapshot.paramMap.get('id')).subscribe((response:any)=>{
      this.loadingJoinButton = false
      console.log(response)
      console.log(response)
      
      this.spinner.hide()
      // window.open(
      //   'https://www.quackit.com/javascript/examples/sample_popup.cfm',
      //   'popUpWindow',
      //   ` height=500,
      //     width=400,
      //     left=100,
      //     top=100,
      //     resizable=yes,
      //     scrollbars=yes,
      //     toolbar=yes,
      //     menubar=no,
      //     location=no,
      //     directories=no,
      //     status=yes`
      //   );




      const win = window.open(
        response.data.link,
        'Secure Payment',` height=500,
        width=400,
        left=100,
        top=100,
        resizable=yes,
        scrollbars=yes,
        toolbar=yes,
        menubar=no,
        location=no,
        directories=no,
        status=yes`);
        const timer = setInterval(() => {
          if (win.closed) {
            this.checkConferenceJoinEmail2()
            // this.getConference(this.route.snapshot.paramMap.get('id'))
          clearInterval(timer);
          // alert('"Secure Payment" window closed!');
        }
      }, 500);

        // this.joinStatus = response
        // if(response.data.result.is_joined){
        //   this.isJoined = true
        // }else{
        //   const initialState :any = {
        //     conferenceId: this.route.snapshot.paramMap.get('id'),
        //     email:this.form.value['email']
        //   };
        //   this.bsModalRef = this.modalService.show(ConferenceRegistrationComponent, {initialState});
        //   // this.bsModalRef = this.modalService.show(ConferenceRegistrationComponent);
        //   this.bsModalRef.content.closeBtnName = 'Close';
        // }
        
      })
  }
  get f() {return this.form.controls}
  submit(){
    this.submitted = true;
    if (this.form.invalid) { 
      // this.loadingJoinButton = false

      return
      // window.scroll({ top: 0, left: 0, behavior: 'smooth' });return 
    }
    this.loadingJoinButton = true

    console.log(this.form.value)

    
  this.checkConferenceJoinEmail()
  }
  checkConferenceJoinEmail(){
      // alert(JSON.stringify({...this.form.value}))
    // this.spinner.show()
    this.service.checkConferenceJoinEmail(this.form.value['email'],this.route.snapshot.paramMap.get('id')).subscribe((response:any)=>{
      this.loadingJoinButton = false
        console.log(response)
        console.log(response)
        this.joinStatus = response
        if(response.data.result.is_joined){
          this.isJoined = true
        }else{
          const initialState :any = {
            conferenceId: this.route.snapshot.paramMap.get('id'),
            email:this.form.value['email']
          };
          // this.bsModalRef = this.modalService.show(ConferenceRegistrationComponent, {initialState});
          // this.bsModalRef = this.modalService.show(ConferenceRegistrationComponent);
          // this.bsModalRef.content.closeBtnName = 'Close';
          
          this.bsModalRef = this.modalService.show(ConferenceRegistrationComponent, {initialState});
          this.bsModalRef.content.onClose.subscribe(result => {
            console.log('results', result);
        })
        }
        
      })
  }
  checkConferenceJoinEmail2(){
    // alert(JSON.stringify({...this.form.value}))
  // this.spinner.show()
  this.service.checkConferenceJoinEmail(this.form.value['email'],this.route.snapshot.paramMap.get('id')).subscribe((response:any)=>{
    this.loadingJoinButton = false
      console.log(response)
      console.log(response)
      this.joinStatus = response
      this.isJoined = true

      
    })
}
  join(){
    // conferenceId = null
    setTimeout(() => {
      
      
    }, 1000);
  }
  getConference(conferenceId){
    this.spinner.show()
    this.service.conferenceShowById(conferenceId).pipe(map(response=>response['data'])).subscribe(response=>{
      console.clear()
      this.conference = response.conferences_seminars
      this.recommended = response.recomend_conferences_seminars
      console.log(response.recomend_conferences_seminars)
      this.spinner.hide()
    })
  }
  
  getCertificate() {
    // const initialState: any = {
    //   initialState: {
    //     list: [
    //       'Open a modal with component',
    //       'Pass your data',
    //       'Do something else',
    //       '...'
    //     ],
    //     title: 'Modal with component'
    //   }
    // };
    this.bsModalRef = this.modalService.show(CertificateComponent);
    this.bsModalRef.content.closeBtnName = 'Close';
  }
}
