import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public submitted = false;
  public form:FormGroup;
  public countries = []
  private nationality;
  private residence;
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  constructor(private router: Router,private formbuilder:FormBuilder,private service:AuthenticationService,private spinner:NgxSpinnerService) { 
    if (this.service.currentUserValue) { this.router.navigate(['/']) }
  }

  ngOnInit(): void {
    this.getCountries()
    this.form = this.formbuilder.group({
      // firstNameAr: ['', [[Validators.required], Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      // middleNameAr: ['', [[Validators.required], Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      // lastNameAr: ['', [[Validators.required], Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      // firstName: ['', [[Validators.required], Validators.pattern('^[A-Za-z][A-Za-z]*$')]],
      // middleName: ['', [[Validators.required], Validators.pattern('^[A-Za-z][A-Za-z]*$')]],
      // lastName: ['', [[Validators.required], Validators.pattern('^[A-Za-z][A-Za-z]*$')]],
      firstNameAr: ['', [Validators.required]],
      middleNameAr: ['', [Validators.required]],
      lastNameAr: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      middleName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
      title: ['', [Validators.required]],
      nationality: ['', [Validators.required]],
      residence: ['', [Validators.required]],
      specialization: ['', [Validators.required]],
      specializationNumber: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
      mobile2Nd: ['', [Validators.nullValidator]],
    })
  }
  getCountries(){
    this.service.countries().pipe(map(response=>response['data'])).subscribe(countries=>{
      console.log(countries)
      this.countries = countries
    })
  }
  onChangeValue(e,formKey){
    if(this.form.get(formKey).value){
      this.form.controls[formKey].setErrors({'notListed': true});
    }
  }
  onSelectValue(e,formKey){
    this[formKey] = this.countries.find(c => c.name_by_app_lang === e.value)
    this.form.controls[formKey].setValue(e.value);
  }
  get f() {return this.form.controls}
  submit(){
    this.submitted = true;
    if (this.form.invalid) { 
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });return 
    }
    this.spinner.show()
    const form = {
      ...this.form.value,
      nationality:this.nationality,
      residence:this.residence,
    }
    this.service.register(form).subscribe((response:any)=>{
      console.log(response)
      
      this.service.sendCodeToEmail(this.form.value['email']).pipe(map(response=>response['data'])).subscribe(response=>{
        this.router.navigate(["/confirm",this.form.value['email']])
        this.spinner.hide()
      })

      // console.log(response)
      // this.spinner.hide()
      // const loginForm = {
      //   email : this.form.value['email'],
      //   password : this.form.value['password'],
      // }
      // this.service.login(loginForm).subscribe((response:any)=>{
      //   console.log(response)
      //   console.log(response)
      //   this.spinner.hide()
         
      //       this.router.navigate(["/profile"])
      // })
    })
  }
}
 