import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'dynamic-form-builder',
  template:`
 
    <form (ngSubmit)="onSubmit.emit(this.form.value)" [formGroup]="form" class="form-horizontal">
      <div *ngFor="let field of fields">
          <field-builder [field]="field" [form]="form"></field-builder>
      </div>
      <div class="form-row"></div>
      <div class="form-group row">
        <div class="col-md-3"></div>
        <div class="col-md-12">
          <button (click)="submit()" type="submit" [disabled]="!form.valid" class="btn btn-sandal w-100">Save</button>
        </div>
      </div>
    </form>
  `,
})
export class DynamicFormBuilderComponent implements OnInit {
  @Output() onSubmit = new EventEmitter();
  @Input() fields: any[] = [];
  @Input() conferenceId;
  @Input() email;
  form: FormGroup;
  constructor(private service:AppService,private spinner:NgxSpinnerService,private modalService: BsModalService) { 
     
  }

  ngOnInit() {
      console.log(this.email)
      console.log(this.email)
      console.log(this.email)
      console.log(this.email)
    let fieldsCtrls = {};
    for (let f of this.fields) {
      if (f.type != 'checkbox') {
        fieldsCtrls[f.name] = new FormControl(f.value || '', Validators.required)
      } else {
        let opts = {};
        for (let opt of f.options) {
          opts[opt.key] = new FormControl(opt.value);
        }
        fieldsCtrls[f.name] = new FormGroup(opts)
      }
    }
    this.form = new FormGroup(fieldsCtrls);
  }
  private bsModalRef?: BsModalRef;

  submit(){
      this.spinner.show()
      console.log(this.form.value)
      this.service.conferenceJoin(this.form.value,this.conferenceId,this.email).subscribe(response=>{
          console.log(response)
                    Swal.fire({
            title: 'success!',
            text: `The application has been sent successfully`,
            icon: 'success',
          })
    this.spinner.hide()
    // window.location.reload()
    this.service.subject$.next(true)
    this.modalService.hide()
    
// Swal.fire({
//     title: 'success!',
//     text: `The application has been sent successfully`,
//     icon: 'success',    
//   }).then((result) => {
//     window.location.reload()
//     this.modalService.hide()
//     /* Read more about isConfirmed, isDenied below */
//     // if (result.isConfirmed) {
//     //   Swal.fire('Saved!', '', 'success')
//     // } else if (result.isDenied) {
//     //   Swal.fire('Changes are not saved', '', 'info')
//     // }
//   })

        //   Swal.fire({
        //     title: 'success!',
        //     text: `The application has been sent successfully`,
        //     icon: 'success',
        //   })
       
        })
    }
}
