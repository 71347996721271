import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent implements OnInit {
  public submitted = false;
  public form:FormGroup;

  constructor(private router: Router,private formbuilder:FormBuilder,private service:AuthenticationService) { 
    // if (this.service.currentUserValue) { this.router.navigate(['/']) }
  }

  ngOnInit(): void {
    this.form = this.formbuilder.group({
      // firstNameAr: ['', [[Validators.required], Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      // middleNameAr: ['', [[Validators.required], Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      // lastNameAr: ['', [[Validators.required], Validators.pattern('^[\u0621-\u064A\u0660-\u0669 ]+$')]],
      // firstName: ['', [[Validators.required], Validators.pattern('^[A-Za-z][A-Za-z]*$')]],
      // middleName: ['', [[Validators.required], Validators.pattern('^[A-Za-z][A-Za-z]*$')]],
      // lastName: ['', [[Validators.required], Validators.pattern('^[A-Za-z][A-Za-z]*$')]],
      firstNameAr: ['', [Validators.required]],
      middleNameAr: ['', [Validators.required]],
      lastNameAr: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      middleName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
      title: ['', [Validators.required]],
      nationality: ['', [Validators.required]],
      specialization: ['', [Validators.required]],
      specializationNumber: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
      mobile2Nd: ['', [Validators.nullValidator]],
    })
  }
  get f() {return this.form.controls}
  submit(){
    this.submitted = true;
    if (this.form.invalid) { 
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });return 
    }
    console.log(this.form.value)
    alert(JSON.stringify({...this.form.value}))
    // this.spinner.show()
    // this.service.login(this.form.value).subscribe((response:any)=>{
    //   // console.log(response)
    //   // this.spinner.hide()
    //       // Swal.fire(
    //       //   `success`,
    //       //   `Welcome back, ${response.user.name}`,
    //       //   `success`
            
    //       // )
    //       this.router.navigate(["/home"])
    // })
  }
}
 