import { Component, OnInit } from '@angular/core';
import AOS from 'aos';

@Component({
  selector: 'app-social-responsibility',
  templateUrl: './social-responsibility.component.html',
  styleUrls: ['./social-responsibility.component.scss']
})
export class SocialResponsibilityComponent implements OnInit {
  events = [
    "https://f.hubspotusercontent30.net/hubfs/6889138/AV_Images-May2021/Images/corporate-popup2.jpg",
    "https://velocityavs.com/wp-content/uploads/2016/10/Draft-Kings-1-1024x684.jpg",
    "https://www.theshowbusiness.com/wp-content/uploads/2016/08/Corporate-Main-page-pic.jpg",
  ]
  constructor() { }

  ngOnInit(): void {
    AOS.init();
  }

}
