import { Component, OnInit } from '@angular/core';
import AOS from 'aos';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-conferences',
  templateUrl: './conferences.component.html',
  styleUrls: ['./conferences.component.scss']
})
export class ConferencesComponent implements OnInit {
  conferences :any = []
  constructor(private spinner:NgxSpinnerService,private service:AppService) { }

  ngOnInit(): void {
    AOS.init();
    this.getConferences()
  }
  getConferences(){
    this.spinner.show()
    this.service.conferences().pipe(map(response=>response['data'])).subscribe(response=>{
      console.clear()
      console.log(response)
      this.conferences = response
      this.spinner.hide()
    })
  }
}
