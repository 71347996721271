import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OurBusinessComponent } from './components/our-business/our-business.component';
import { ExhibitionsComponent } from './components/exhibitions/exhibitions.component';
import { ActivitiesComponent } from './components/activities/activities.component';
import { MediaAndDocumentationComponent } from './components/media-and-documentation/media-and-documentation.component';
import { MarketingComponent } from './components/marketing/marketing.component';
import { WorkshopsComponent } from './components/workshops/workshops.component';
import { TarainingComponent } from './components/taraining/taraining.component';
import { FreeCounselingComponent } from './components/free-counseling/free-counseling.component';
import { LoginComponent } from './components/auth/login/login.component';
import { ConferencesComponent } from './components/conferences/conferences.component';
import { ConferenceComponent } from './components/conference/conference.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CertificateComponent } from './components/conference/certificate/certificate.component';
import { RegisterComponent } from './components/auth/register/register.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SocialResponsibilityComponent } from './components/social-responsibility/social-responsibility.component';
import { BlogComponent } from './components/blog/blog.component';
import { VirtualTourComponent } from './components/virtual-tour/virtual-tour.component';
import { ProfileComponent } from './components/auth/profile/profile.component';
import { UpdateProfileComponent } from './components/auth/update-profile/update-profile.component';
import { ContactUsComponent } from './components/auth/contact-us/contact-us.component';
import { ConfirmComponent } from './components/auth/contact-us/confirm/confirm.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { ErrorInterceptor } from './helpers/error.interceptor';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { TranslocoRootModule } from './transloco-root.module';
import { Language } from './helpers/changeLanguage';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { AuthGuard } from './guards/auth.guard';
import { NgOtpInputModule } from  'ng-otp-input';
import { CountdownModule } from 'ngx-countdown';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
import { DynamicFormBuilderModule } from './components/conference/dynamic-form-builder/dynamic-form-builder.module';
import { CareersComponent } from './components/careers/careers.component';
import { ReturnsPolicyComponent } from './components/returns-policy/returns-policy.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    OurBusinessComponent,
    ExhibitionsComponent,
    ActivitiesComponent,
    MediaAndDocumentationComponent,
    MarketingComponent,
    WorkshopsComponent,
    TarainingComponent,
    FreeCounselingComponent,
    LoginComponent,
    ConferencesComponent,
    ConferenceComponent,
    CertificateComponent,
    RegisterComponent,
    SocialResponsibilityComponent,
    BlogComponent,
    VirtualTourComponent,
    ProfileComponent,
    UpdateProfileComponent,
    ContactUsComponent,
    ConfirmComponent,
    ResetPasswordComponent,
    CareersComponent,
    ReturnsPolicyComponent,
    PrivacyPolicyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxMapboxGLModule.withConfig({
      accessToken: 'pk.eyJ1IjoiemV4YXhhd3lueSIsImEiOiJjazJuZDMzNGYwbXc5M2dueDJhazBmajJmIn0.tRzMu-yiZZEF-0RYt5H8Ow', 
    }),
    SharedModule,
    CarouselModule,
    ModalModule.forRoot(),
    HttpClientModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    TranslocoRootModule,
    TypeaheadModule.forRoot(),
    NgxIntlTelInputModule,
    NgOtpInputModule,
    FormsModule,
    CountdownModule ,
    DynamicFormBuilderModule,
    NgxDocViewerModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AuthGuard,
    Language,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
